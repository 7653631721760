/* eslint-disable react-native/no-inline-styles */
import React, {useCallback} from 'react';

import {useDropzone} from 'react-dropzone';
import {uploadProfilePhoto, updateProfile} from './pofile.service';
import {flex} from '@/components/style';
const Upload = ({info, getUserInfo, pic}: any) => {
  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      console.log(acceptedFiles);
      const ret: any = await uploadProfilePhoto({file: acceptedFiles[0]});
      await updateProfile(info?.userName, ret);
      getUserInfo();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [info],
  );
  const {getRootProps, getInputProps} = useDropzone({onDrop});
  return (
    <div {...getRootProps()} style={flex.center}>
      <input {...getInputProps()} />
      <div style={{marginTop: 4}}>{pic}</div>
    </div>
  );
};

export default Upload;
